import { Button } from "@/components/ui/button";
import { Paragraph } from "@/components/ui/texts/paragraph";
import { ButtonModel } from "@/lib/models/ContenfulSections";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { Markdown } from "../markdown";
import { ContentfulLivePreview } from "@contentful/live-preview";
import ButtonComponent from "../ButtonComponent";
import { Heading } from "@/components/ui/Heading";

export interface VerticalHeroSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    title: string;
    headingTitle: ContentfulRichTextModel;
    subtitle: string;
    image: ContentfulImageModel;
    backgroundImage: ContentfulImageModel | undefined;
    primaryCta: ButtonModel | undefined;
    secondaryCta: ButtonModel | undefined;
    hookSubtitle: string | undefined;
}

const VerticalHeroSectionComponent: React.FC<VerticalHeroSectionModel> = (verticalHeroSectionModel) => {
    return (
        <div
            style={{ backgroundImage: `url('${verticalHeroSectionModel.backgroundImage?.url ?? ""}')` }}
            className={cn("flex flex-col items-center w-full",
                verticalHeroSectionModel.backgroundImage
                && ("lg:bg-fill lg:bg-no-repeat lg:bg-center lg:bg-contain"))
            }
        >
            <div className="flex flex-col items-center min-h-[20rem] w-full max-w-[1440px]">
                <div className="z-10 max-w-[1440pt] w-full flex flex-col items-center pt-6 lg:pt-[120px]">
                    <div   
                        className="px-6 lg:px-0 w-full lg:max-w-[67%]"
                        {...ContentfulLivePreview.getProps({
                            entryId: verticalHeroSectionModel.sys.id,
                            fieldId: "headingTitle",
                            locale: "en-US",
                        })}
                    >
                        <Markdown
                            forcedBlock="heading-1"
                            headingStyle="headline"
                            className="text-center text-secondary-200 whitespace-break-spaces"
                            content={verticalHeroSectionModel.headingTitle} />
                    </div>
                    <Heading
                        {...ContentfulLivePreview.getProps({
                            entryId: verticalHeroSectionModel.sys.id,
                            fieldId: "subtitle",
                            locale: "en-US",
                        })}
                        typeVariant={"h3"}
                        styleVariant={"largeParagraph"}
                        className="px-6 lg:px-0 pt-6 text-secondary-200 whitespace-break-spaces w-full lg:max-w-[67%] text-center">
                            {verticalHeroSectionModel.subtitle}
                    </Heading>
                    <div className="flex flex-row items-center pt-10 gap-4">
                        <ButtonComponent 
                            contenfulEntryId={verticalHeroSectionModel.sys.id}
                            contentfulFieldId={"primaryCta"}
                            buttonVariant={"darkFilled"}
                            buttonModel={verticalHeroSectionModel.primaryCta} />
                    
                        <ButtonComponent 
                            contenfulEntryId={verticalHeroSectionModel.sys.id}
                            contentfulFieldId={"secondaryCta"}
                            buttonVariant={"darkOutline"}
                            buttonModel={verticalHeroSectionModel.secondaryCta}/>

                    </div>
                    {verticalHeroSectionModel.hookSubtitle && (
                        <Heading
                            {...ContentfulLivePreview.getProps({
                                entryId: verticalHeroSectionModel.sys.id,
                                fieldId: "hookSubtitle",
                                locale: "en-US",
                            })}
                            typeVariant={"h4"}
                            styleVariant={"smallParagraph"}
                            className="text-primary-200 max-w-[50%] text-center pt-4">
                            {verticalHeroSectionModel.hookSubtitle}
                        </Heading>

                    )}
                    <div className="w-full lg:px-[155px] px-6 relative pt-6">
                        {verticalHeroSectionModel.image && verticalHeroSectionModel.image.url && (
                            <div
                                {...ContentfulLivePreview.getProps({
                                    entryId: verticalHeroSectionModel.sys.id,
                                    fieldId: "image",
                                    locale: "en-US",
                                })}
                                className="relative w-fill h-full">
                                <img src={verticalHeroSectionModel.image.url}
                                    alt={verticalHeroSectionModel.title}
                                    className="object-contain rounded-t-[8px]" />
                                <div
                                    className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-background-900"></div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>

    );
};

export default VerticalHeroSectionComponent;